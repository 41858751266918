<template>
  <section class="footer">
    <div class="bg">
      <div class="footer__header" id="footer">
        <div class="header-left">
          <h3>{{ t("footer.header.title") }}</h3>
          <p>{{ t("footer.header.desc") }}</p>
        </div>
        <a
          class="btn-right"
          :class="{ padding__en: lang === 'en' }"
          target="_blank"
          href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456"
        >
          {{ t("footer.header.button") }}
        </a>
      </div>
    </div>
    <div class="footer__contact">
      <ul class="horizontal-menu">
        <li class="menu-item">
          <h3>{{ t("footer.contact.product") }}</h3>
          <a target="_blank" href="https://ms.ytdevops.com">{{
            t("footer.contact.free")
          }}</a>
          <router-link target="_blank" to="/price">{{
            t("footer.contact.longTerm")
          }}</router-link>
          <a
            target="_blank"
            href="https://ms.ytdevops.com/pubKnowledge/8954eeec-f496-4f2d-84d3-ce62266d5ff7"
            >{{ t("footer.contact.termsOfUse") }}</a
          >
          <a
            target="_blank"
            href="https://ms.ytdevops.com/pubKnowledge/85b934dc-d50c-4d73-9f48-9d89dd07c439"
            >{{ t("footer.contact.privacyPolicy") }}</a
          >
        </li>
        <li class="menu-item">
          <h3>{{ t("footer.contact.company") }}</h3>
          <router-link target="_blank" to="/about-us">{{
            t("footer.contact.aboutUs")
          }}</router-link>
          <a
            target="_blank"
            href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456"
            >{{ t("footer.contact.joinUs") }}</a
          >
          <a target="_blank" href="https://tools.ytdevops.com/">{{
            t("footer.contact.ITtool")
          }}</a>
        </li>
        <li class="menu-item">
          <h3>{{ t("footer.contact.address") }}</h3>
          <p :class="{ font__en: lang === 'en' }" style="color: #999">
            {{ t("footer.contact.addLine1") }}
          </p>
          <p :class="{ font__en: lang === 'en' }" style="color: #999">
            {{ t("footer.contact.addLine2") }}
          </p>
        </li>
        <li class="menu-item">
          <h3>{{ t("footer.contact.service") }}</h3>
          <h2 style="font-size: 17px; margin-bottom: 1rem">
            <span style="color: rgb(155, 156, 157)">{{
              t("footer.contact.china")
            }}</span
            >&ensp;+86 138 1743 8496
          </h2>
          <h2 style="font-size: 17px">
            <span style="color: rgb(155, 156, 157)">{{
              t("footer.contact.overseas")
            }}</span
            >&ensp;+65 8312 1028
          </h2>
          <p style="color: #999">support@ytdevops.com</p>
        </li>
        <li class="menu-item">
          <img v-if="lang === 'zh'" src="../../assets/imgs/map-zh.png" alt="" />
          <img v-if="lang === 'en'" src="../../assets/imgs/map-en.png" alt="" />
        </li>
      </ul>
    </div>
    <!-- 底部信息部分 -->
    <div class="footer__main">
      <!-- 回到顶部 -->
      <div class="car-box" @click="toTop">
        <img src="../../assets/imgs/car-move.png" alt="" />
      </div>
      <!-- 工具箱 -->
      <div class="tool-box" @click="toTool">
        <img src="../../assets/imgs/toolbox.png" alt="" />
      </div>
      <!-- 主体内容 -->
      <div class="container">
        <!-- logo和文字 -->
        <div class="leftContent">
          <div class="logo-box">
            <img src="../../assets/imgs/logo.png" alt="" />
          </div>
          <!-- 文字图片 -->
          <img
            style="width: 320px; height: 45px; margin-top: 8rem"
            src="../../assets/imgs/footer-text.png"
            alt=""
          />
        </div>
        <!-- 分割线 -->
        <div class="vertical-line"></div>
        <!-- B站 油管 etc -->
        <div class="midContent">
          <div class="imgOutsideBox">
            <img
              @click="goToBSite"
              class="outside"
              src="../../assets/imgs/bilibili.png"
              alt=""
            />
            <img
              @click="goToYT"
              class="outside"
              style="margin-left: 6rem"
              src="../../assets/imgs/youtube.png"
              alt=""
            />
            <img
              @click="goToLK"
              class="outside"
              style="margin-left: 6rem"
              src="../../assets/imgs/linkedin.png"
              alt=""
            />
          </div>
          <div class="phoneBox">
            <img src="../../assets/imgs/phone.png" alt="" />
            <p>+86 138 1743 8496</p>
          </div>
        </div>
        <!-- 二维码 -->
        <div class="wechatBox">
          <div class="wechatImg">
            <div class="wechatImg-box">
              <img src="../../assets/imgs/OfficialAccounts.png" alt="" />
              <p>{{ t("footer.contact.OfficialAccounts") }}</p>
            </div>
            <div class="wechatImg-box" style="margin-left: 4rem">
              <img src="../../assets/imgs/WeChatChannel.png" alt="" />
              <p>{{ t("footer.contact.WeChatChannel") }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部信息 -->
      <div class="bottom">
        <ul class="bottom-list">
          <li>{{ t("footer.main.right") }}</li>
          <li>{{ t("footer.main.company") }}</li>
          <li>
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              >{{ t("footer.main.icp") }}</a
            >
          </li>
          <li>
            <img src="../../assets/imgs/beian.png" alt="" /><a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050702011070"
              target="_blank"
              >{{ t("footer.main.record") }}</a
            >
          </li>
          <li style="cursor: pointer" @click="openImage">
            {{ t("footer.main.licence") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { ref } from "vue";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
// 映射显示名称到实际文件名
const imageMap = {
  displayName: "licence.png",
};

const openImage = () => {
  const actualFileName = imageMap["displayName"];
  const imageUrl = `/img/${actualFileName}`;
  window.open(imageUrl, "_blank");
};

function goToBSite() {
  window.open(
    "https://space.bilibili.com/520603057?spm_id_from=333.1007.0.0",
    "_blank"
  );
}

function goToYT() {
  window.open("https://www.youtube.com/@YunTi-Devops", "_blank");
}

function goToLK() {
  window.open("https://www.linkedin.com/company/yuntitech", "_blank");
}

function toTop() {
  // const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  // // 这里如果写大于0，会出现不可下滑的bug
  // if (scrollTop > 1) {
  //   window.requestAnimationFrame(toTop);
  //   window.scrollTo(0, scrollTop - scrollTop / 8);
  // }
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
function toTool() {
  window.open(
    "https://tools.ytdevops.com/?lang=" +
      (window.localStorage.getItem("lang")
        ? window.localStorage.getItem("lang")
        : "zh")
  );
}
</script>

<style lang="scss" scoped>
.padding__en {
  padding: 0.6rem 1.2rem;
}
.font__en {
  font-size: 1.4rem;
}
</style>
