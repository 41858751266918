export default {
    topBar: {
        homepage: 'Home',
        price: 'Price',
        aboutUs: 'About Us',
        funSafety: 'Functional Safety',
        clientCases: 'Client Case Studies',
        automotiveToolchain: 'Automotive Toolchain',
        industrialIntelligentManufacturing: 'Industrial Intelligent Manufacturing',
        informationSecurity: 'Cyber Security',
        yingxiao:'MappingSpace Private Deployment Lifetime Free Version - View Details',
        yingxiao1:'MappingSpace Private Free Version'

    },
    clientCase: {
        oneName: 'Aotecar New Energy Technology',
        twoName: 'Shanghai Tongyu Automotive Technology',
        threeName: 'Ningbo Fuerda Smartech'
    },
    btn: {
        btnTrail: "Free trial",
        btnDocument: "Product instructions",
        tryIt: "Try it now",
        contact: "Contact us",
        join: 'Join now',
        demo: 'Demo',
        learn:'More'
    },
    applyDialog: {
        title: 'Apply for a demo',
        info: 'Please provide your contact info so that we can reach out to you',
        label: {
            name: 'Name',
            email: 'Corporate Email',
            phone: 'Mobile',
            careAbout: 'Any specific features you would like to know?'
        },
        formInfo: {
            name: 'please input name',
            email: 'please input corporate email',
            emailFormatError: 'The business email format is incorrect',
            phone: 'please input mobile',
            phoneFormatError: 'The phone number format is incorrect'
        },
        confirm: 'confirm',
        cancel: 'cancel'
    },
    homepage: {
        Jumbotron: { // 顶部巨幕
            // word1: 'Compliant with ASPICE standards',
            // word2: 'One-stop Requirements ',
            // word3: 'Development ',
            // word4: 'Test management platform'
            word3: 'Follow the official account for more information',
            word: 'Agile · ASPICE · AI',
            word2: 'One stop requirement, development, testing management platform',
            carIndustry: 'A cutting-edge collaborative platform in automotive development'
        },
        cooperation: { // 合作
            title: 'Nine management scenarios',
            requirement: 'Requirement management',
            workItem: 'Task management',
            test: 'Test management',
            baseline: 'Baseline management',
            change: 'Change management',
            publish: 'Release management',
            onlineJudge: 'Online review',
            document: 'Document management',
            agile: 'Scrum & Kanban',
            secondTitle: '<span>AI</span> · ASPICE · Agile → MappingSpace',
            description: 'MappingSpace integrates the latest progress of AIGC by useing the LLM to generate R&D process docs, which greatly improves the R&D efficiency. R&D engineers can directly generate R&D process docs, such as specification of requirements, architecture and test cases in MappingSpace, and transform them to tasks automatically.',
            oneAI: {
                title: 'AI Plus',
                description: 'AI Supplement and improve existing R&D process docs',
            },
            twoAI: {
                title: 'AI UML',
                description: 'AI generate editable static and dynamic architecture diagrams based on architecture specification, greatly improving the working efficiency of architects',
            },
            threeAI: {
                title: 'AI Coding',
                description: 'AI generate code framework, based on the existing detailed design specification, greatly improve the efficiency of coders',
            }
        },
        replace: 'Replaces',
        requirement: { // Requirement management
            info: 'Requirement Management',
            title: 'Mind map type requirement management; strongest traceability by nature',
            desc: 'With the help of mind map floating window, user can quickly view and edit any task properties or look through the context of any requirement and its related issues; with the help of legend, user can view the status, assignee and other elements of issues by color; the efficiency of editing requirements and assigning tasks will be increased by more than 10 times.',
            // li1: 'Millions of yuan',
            li1: '>1 million RMB',
        },
        workItem: {
            info: 'Task Management',
            title: 'Inquiry of relation chart and coverage',
            desc: 'By viewing the types of related issues, user can display the coverage of any type of any issues in real time (requirements coverage, architecture coverage, test case coverage, etc.) , which fully meets the traceability of ASPICE standard.',
            // li1: 'Hundreds of thousands of yuan',
            li1: '>100k RMB',
        },
        test: {
            info: "Test Management",
            title: 'Create test plans accurately,add test cases automatically',
            desc: 'Mind map can be used to create and execute test cases.After test cases are executed, a test report will be automatically generated, and it can be exported to pdf by one click .Test report comprises bug data, test case data, requirement coverage, detailed results and API test report.',
            // li1: 'Tens of thousands of yuan',
            li1: '>10k RMB',
            li2: 'Open source',
        },
        baseline: {
            info: 'Baseline Management',
            title: 'Create a development baseline,view the records of baseline changes',
            desc: 'After adding a node to a baseline, user can start the baseline. User can freely choose whether to lock the baseline or not. The locked baseline cannot be directly changed, and it must pass the online review of the Change Committee. There are clear records of addition, change and deletion of baseline contents. ',
            // li1: 'Millions of yuan',
            li1: '>1 million RMB',
            li2: 'Open source',
        },
        change: {
            info: 'Change Management',
            title: 'Create change branches for mandatory review by multi persons ',
            desc: 'For any type of task, user can initiate a change request and invite the Change Committee or the specified person to review it Customize rules for review & approval, and fully master the impact of changes.',
            // li1: 'Millions of yuan',
            li1: '>1 million RMB',
        },
        publish: {
            info: 'Release Management',
            title: 'List of historical records of published versions',
            desc: 'After a version is created for publishing, the version can be related to Sprint, and the issue in Sprint will be automatically added to the version for publishing, and the responsible publisher will be specified By relating to a test plan, user can check the progress of the test plan at any time, so as to determine whether the version can be published or not, and a report on version publishing will be issued automatically.',
            li1: 'Open source',
        },
        onlineJudge: {
            info: 'Online Review',
            title: 'Mind map review mode brings absolutely high efficiency',
            desc: 'Distribute requirements, test cases and other types of issue, and invite review Review by multiple people synchronously, fill in review results in batches rapidly, customize review passing rules, and display review results in real time.',
            li1: 'E-mail',
            li2: 'Offline review meeting',
            li3: 'Several days',
        },
        document: {
            info: 'Document Management',
            title: 'Collaboratively edit documents online in real time',
            desc: 'It is suitable for enterprises to carry out co-edit of documents by multiple people simultaneously, able to form a knowledge base of processes and documents of enterprises Documents can be converted into mind map just by one-click; for task trace, user can switch between document view and mind map view seamlessly.',
            li1: 'Feishu',
            // li2: 'Hundreds of thousands of yuan',
            li2: '>100k RMB',
            // li3: 'Tens of thousands of yuan',
            li3: '>10k RMB',
            li4: 'Open source',
        },
        agile: {
            info: 'Scrum & Kanban',
            title: 'Support Scrum and Kanban and make agile development team more efficient',
            desc: 'View agile Kanban according to the mind map or the mode of responsible person Task flow flows quickly, and Sprint report can be automatically issued.',
            // li1: 'Hundreds of thousands of yuan'
            li1: '>100k RMB',
        },
        case: {
            Aotecar: 'Aotecar',
            Tongyu: 'Tongyu',
            Fuerda: 'Fuerda',
            caseTitle1: 'Comprehensive Automotive Thermal Management Solution',
            caseTitle2: 'Leading Technology Innovator in Chassis Control',
            caseTitle3: 'High-End Automotive Intelligent Optoelectronic System',
            caseTitle1Text: 'With the MappingSpace R&D management system, Aotejia completed the overall online migration of project data, meeting stringent requirements from European clients.',
            caseTitle2Text: 'With the MappingSpace R&D management system, Tongyu Automotive achieved efficient management of wire-controlled chassis functional safety projects, meeting the standards set by ISO 26262.',
            caseTitle3Text: 'With the MappingSpace R&D management system, we achieved the localization of IBM Doors software, greatly exceeding the efficiency and user experience of the original tools.',
            caseRead:'Read more',
            moreResources: 'More resources',
            resourceTitle1: 'Automotive toolchain',
            resourceTitle2:'Intelligent Manufacturing',            
            resourceTitle3: 'Download white paper',
            resourceTitle4: 'Download testing report',
            resourceTitle5: 'Download management white report',
            resourceTitle1Text: 'MappingSpace brings you a more comprehensive and automotive-savvy research and development management toolchain',
            resourceTitle2Text:'MappingSpace meets high-precision industrial standards, serving as an all-in-one digital R&D management platform.',            
            resourceTitle3Text: 'China Automotive Basic Software Development White Paper 3.0',
            resourceTitle4Text:'China Automotive Basic Software Testing Research Report 1.0',
            resourceTitle5Text:'White Paper on Automotive R&D Process Management',
        }
    },
    footer: { // Footer
        header: {
            title: 'Automobile software toolbox， You are welcome to join us',
            desc: "For more information, please contact us at: support{'@'}ytdevops.com",
            button: 'I want to join the team'
        },
        contact: {
            product: 'Product details',
            free: 'Free trial',
            longTerm: 'Long term use',
            company: 'Enterprise information',
            aboutUs: 'About Us',
            joinUs: 'Join Us',
            ITtool: 'IT-TOOLS',
            service: 'Our services',
            address: 'Our address',
            addLine1: 'No. 1, Guantang Road, Caohu Street, Suzhou City',
            addLine2: 'Caohu Science and Technology Park',
            privacyPolicy: 'Privacy Policy',
            termsOfUse: 'Terms of Use',
            china:'China',
            overseas: 'overseas',
            OfficialAccounts: 'WeChat',
            WeChatChannel: 'Channel',
        },
        main: {
            title: 'Yunti Technology',
            contact: 'Contact Us',
            right: 'Copyright: c 2022Yunti Technology',
            company: 'Organizer: SuzhouYunti Technology',
            icp: 'ICP备案号:苏ICP备2021051249号-1',
            record: '苏公网安备32050702011070号',
            licence:'ICP许可证：苏B2-20240943',
        }
    },
    price: { // Price
        Jumbotron: {
            title: 'Software advantages',
            desc: 'Automobile software toolbox·DevOps centered·Increase efficiency of development, test, operation and maintenance'
        },
        // words: {
        //     word1: 'Meet ASPICE standards',
        //     word2: 'For the automobile industry',
        //     word3: 'Ultra strong',
        //     word4: 'In traceability via mind map',
        //     word5: 'Relationship diagram',
        //     word6: 'Monitoring coverage',
        //     word7: 'Baseline management',
        //     word8: 'Baseline change audit',
        //     word9: 'Online review',
        //     word10: 'Real-time record of review results'
        // },
        compare: {
            item1: 'Meets ASPICE standards ',
            item2: 'Mind map structure·Strongest traceability',
            item3: 'Relation chart',
            item4: 'Word view of requirements',
            item5: 'Baseline management',
            item6: 'Branch change management',
            item7: 'Online review',
            item8: 'Test case management'
        },
        infoCard: {
            bar1: 'Compliant with ASPICE standards of the auto industry',
            bar2: 'Ultra strong traceability Higher order mind map',
            bar3: 'Related chart Monitoring coverage',
            bar4: 'Baseline management Baseline change audit',
            bar5: 'Online review  Real-time record of review'
        },
        mid: {
            title: 'Authoritative security certification ·20 privileges· All-automobile talent team',
            desc: 'One-stop R&D management platform compliant with ASPICE standards'
        },
        heightspe:{
            height1:'45',
            height2:'60'
        },
        priceCard: {
            people: 'Target users',
            userNumber: {
                number1: 'less than 100 users',
                number2: 'more than 10 users',
                number3: ">=30 users"
            },
            onTrial: {
                Trial1: 'Applicable: up to 100 people',
                Trial2: 'Applicable: 10 people and above',
                Trial3: 'Applicable to teams of 30 or more people',
            },
            version: {
                free: 'Free trail version',
                enterprise: 'Enterprise version',
                selfHosting: 'Self-hosting version'
            },
            priceValue: {
                price1: "RMB 0",
                price2: "/user month",
                price3: "50% discount ",
                price4: "for beginners ",
                price5: "RMB 96"
            },
            featureList: {
                featureTitle: 'Product features',
                item1: 'Hosting mode',
                item2: 'Number of target users',
                item3: 'Storage space',
                item4: 'File hierarchy limit',
                item5: 'User authorization management',
                item6: 'Export & import Excel in batch',
                item7: 'Change rule setting',
                item8: 'Review rule setting',
                item9: 'Project template'
            },
            projectList:{
                item1:'Gantt Chart',
                item2:'V-Model panorama',
                item3:'Automated workflow',
                item4:'Wiki',
                item5:'AI robot',
                onePro:'1 project',
            },
            userList: {
                userTitle: 'Number of users',
                item1: '100 people',
                item2: '50 people',
                item3: '100 people',
                item4: '250 people',
                item5: '500 people',
                item6: '1,000 people',
                item7: '>1,000 people'
            },
            peoplePrice:{
                item1:'58 yuan/person month',
                item2:'55 yuan/person month',
                item3:'50 yuan/person month',
                item4:'44 yuan/person month',
                item5:'38 yuan/person month',
            },
            level:'three layers',
            capacity:'1 GB',
            cloud: 'Cloud hosting',
            notLimited: 'Unlimited',
            contactUs: "Contact Us",
            priceTemplate: ' RMB /user/month',
            free: 'Free trial version',
            freeUser: '≤10 people',
            price: 'RMB0/person/month',
            price1: 'Always free',
            notProvide: 'Not available',
            plz: 'For self-hosting version',
            private: 'Please contact us',
            hot: 'HOT',
        },
        priceContrast: {
            function: 'Function',
            ease: 'Ease of use',
            price: 'Cost',
            contrastname: 'Comparsion ',
            contrast: {
                li1: 'AI generation',
                li2: 'Automotive SPICE standard',
                li3: 'Mind map structure ',
                li4: 'V-model/W-model report',
                li5: 'Word/Excel/ReqIF import/export',
                li6: 'Architecture diagram drawing',
                li7: 'Baseline management',
                li8: 'Change management',
                li9: 'Online review/review report',
                li10: 'Test case management',
                li11: 'Learning cost',
                li12: 'Estimate for the privatized deployment version',
                li13: 'UI interface design',
                li14: 'Language',
                li15: 'Purchase/operation and \nmaintenance costs',
                li16:'Secondary development costs'
            },
            polarion: {
                li1: 'No Review Data Report',
                li2: 'Very high. Recommend evaluating after trial deployment for private use',
                li3: 'Extremely high',
                li4:'Windows XP page design style',
                li5: 'Not support Chinese',
                li6:'High purchase cost and expensive annual maintenance and upgrade fees.',
                li7:'High',
            },
            Codebeamer: {
                li1: 'Purchase additional plugins',
                li2: 'Secondary development',
                li3: 'Purchase additional plugins',
                li4: 'Relatively high. Limited Chinese training materials and complex interface design.',
                li5: 'Moderate',
                li6: 'part',
                li7: 'Windows 7 page design style',
                li8: 'Not support Chinese',
                li9: 'High purchase cost and expensive annual maintenance and upgrade fees, perpetual license not supported.',
                li10:'High'
            },
            mappingSpace: {
                li1: 'Low.\nExtensive operation demonstration videos and simple page design.',
                li2: 'Moderate',
                li3:'Windows 11 page design style',
                li4: 'support Chinese',
                li5: 'More competitive \npricing, free upgrades \nto the latest version, \nand supports \nperpetual license.',
                li6:'Moderate'
            }
        }
    },
    aboutUs: { // About Us
        main: {
            title: 'Suzhou Yunti Technology Co., Ltd.',
            desc: 'Suzhou Yunti Technology Co., Ltd. , a technology company based in the auto industry, focuses on developing cloud DevOps products for the industry, aiming to help auto companies complete their digital transformation rapidly. The digitalization of enterprises includes the digitalization of auto R&D, auto intelligent manufacturing and after-sales operation. In the future, the data of R&D, production and after-sales operation of auto companies will be efficiently circulated on a cloud platform. ',
            desc2: '——Yunti Technology'
        },
        values: { // Values
            title: 'Our values',
            sincerity: 'Honesty',
            vision: 'Vision',
            collaboration: 'Collaboration',
            selfDrive: 'Self-driven'
        },
        recruiting: { // Recruitment
            title: 'You are welcome to join us!',
            engineering: 'Engineering',
            frontend: 'Frontend software engineer',
            backend: 'Backend software engineer',
            design: 'Designer',
            UI: 'UI designer',
            devops: 'Devops engineer',
            button: 'Join now'
        },
        cooperations: "Cooperative partners"
    },
    funSafety: {
        hara: {
            info: 'Hazard Analysis and Risk Assessment (HARA)',
            title: 'Automated calculation of functional safety level',
            desc1: 'Pre-set HARA analysis scenarios, helping you get started effortlessly.',
            desc2: 'Based on the HAZOP method, deeply explore functional failures and hazards.',
            desc3: 'Intelligent calculation of ASIL safety levels to define safety objectives clearly.',
            desc4: 'Mind maps present the complete overview of HARA.',
        },
        dfmea: {
            info: 'DFMEA',
            title: 'Updated seven-step approach, real-time tracking of improvement measures',
            desc1: 'Pre-set DFMEA case studies, suitable for beginners with zero experience.',
            desc2: 'Utilizing mind maps, intelligently populate structural, functional, and failure analyses for efficient and convenient use.',
            desc3: 'Automated calculation of SOD, offering insights into priority of measures.',
            desc4: 'Real-time tracking of improvement measures, providing clear data report displays.',
        },
        fta: {
            info: 'Fault Tree Analysis (FTA)',
            title: 'Automated calculation of various FTA metrics',
            desc1: 'Automatic computation of core FTA indicators, identifying system vulnerabilities.',
            desc2: 'Pre-set FTA analysis scenarios, assisting you in quickly grasping the concepts.',
            desc3: 'Based on mind maps, presenting a clear panoramic view of the FTA.',
            desc4: 'Automated calculation of minimal cut sets, minimal path sets, structural importance, and top event occurrence probability, enhancing efficiency.',
        }
    },
    client: { // 客户
        customerPage1: {
            title: {
                aotecar: 'Automotive Thermal Management Integrated Solution',
                TongyuAutomobile: 'Leading technology in Chassis Control',
                Fuerda: 'High-end Automotive Smart Photoelectric System',
            },
            content: {
                aotecar: 'With the help of the MappingSpace development management system, Aotecar has accomplished a complete online migration of project data to meet the stringent requirements of its European customers.',
                TongyuAutomobile: 'With the help of the MappingSpace development management system, Tongyu Automotive has achieved efficient management of the by-wire chassis safety project, thereby meeting the standard requirements proposed by ISO26262.',
                Fuerda: 'With the help of the MappingSpace management system, a domestic replacement for IBM Doors software has been developed, exceeding the original tool in both usage efficiency and user experience by leaps and bounds.',
            }
        },
        customerPage2: {
            title: {
                aotecar: 'About Aotecar',
                TongyuAutomobile: 'About Tongyu',
                Fuerda: 'About Fuerda',
            },
            content1: {
                aotecar: 'Aotecar New Energy Technology Co., Ltd. provides overall solutions to automotive thermal management, and mainly deals with the R&D, production and sales of automotive thermal management systems, including auto AC compressors, HVAC systems, radiators, and battery thermal management systems for new energy vehicles.',
                TongyuAutomobile: 'Tongyu Automotive Technology is a first-tier supplier of intelligent chassis systems for automobiles in China. The Company focuses on the research and industrialization of "new generation wire-controlled chassis core technology" to provide intelligent driving support for smart mobility. Established in 2016, Tongyu Automotive is a key incubation enterprise of Tongji University, a national high-tech enterprise, a national specialized, refined, and new enterprise in Shanghai, and a technology "Little Giant" (cultivation) enterprise in Shanghai. With headquarters and research center in Shanghai, Tongyu Automotive has two production bases in Jiading, Shanghai and Yichun, Jiangxi, and has built an intelligent manufacturing center with an annual production capacity of 1.5 million sets.',
                Fuerda: 'Since its inception in 1995, Ningbo Fuerda Smartech Co., Ltd. has consistently embraced the business philosophy of "Leading the Market with Technologies, Pursuing Development through Innovations." Presently, the company boasts a formidable team of skilled and dedicated R&D professionals, showcasing proficiency in independent innovative design, development, manufacturing, and integrated support services for products governed by intelligent integrated systems encompassing optics, machinery, electric components, and software and hardware elements. The company also excels in the creation of sophisticated molds and intelligent equipment, such as automatic assembly systems and intelligent online inspection production lines.',
            },
            content2: {
                aotecar: "Automotive Air Conditioner Co., Ltd., are both engaged in the research and development, production and sales of automotive AC compressors. Nanjing Aotecar is the world's leading manufacturer of automotive scroll AC compressors, while Mudanjiang Futong is China’s well-known independent brand in piston compressors.",
                TongyuAutomobile: "Since 2012, Tongyu Automotive's core team has been independently developing core technology for wire-controlled chassis. The company has outstanding R&D capabilities and profound technical accumulation in this field. Tongyu Automotive's products cover intelligent braking series products such as wire-controlled electronic hydraulic braking system (EHB), integrated wire-controlled electronic hydraulic braking system (iEHB), electronic parking brake system (EPB), anti-lock braking system (ABS), and electronic stability control system (ESC).",
                Fuerda: 'Ningbo Fuerda Smartech Co., Ltd. operates three cutting-edge R&D centers and five state-of-the-art manufacturing bases strategically located in Cixi, Ningbo, and Shanghai. Additionally, the company has established a subsidiary and an R&D center in Germany, facilitating synchronized design and development, marketing services, and collaborative efforts with overseas OEMs. Fuerda inaugurated a production base in Romania, enhancing its supply capacity to Europe and global markets, thereby fortifying its commitment to exploring and expanding in overseas markets.',
            },
            content3: {
                aotecar: "The corporation's two wholly-owned subsidiaries, Nanjing Aotecar New Energy Technology Co., Ltd. and Mudanjiang Foton Another subsidiary, Air International Thermal System Group, is a technically advanced manufacturer of auto air conditioning products, with its business spread to the United States, Europe, Asia, etc. AITS serves internationally renowned customers including GM, Ford and JLR, as well as a wide range of customers in China and other emerging markets. Currently, AITS has more than 8,000 employees and 158 independent intellectual property rights and invention patents. Through decades of R & D accumulation, AITS has gradually nurtured its core technology.",
                TongyuAutomobile: "Tongyu has provided more than 100 models of intelligent braking products to over 80 well-known clients. It is one of the few companies worldwide with the capability to mass-produce intelligent braking products. Tongyu's mission is to make cars safer, more comfortable, and smarter.",
                Fuerda: `The company has established a sound management system and passed IATF16949, ISO45001 and ISO14001 certifications. Fuerda has been successively rated as "National Key High-tech Enterprise" and "National Key High-tech Enterprise in the Torch Program", "Zhejiang Green Enterprise", "Zhejiang High-tech Enterprise Research and Development Center", "Zhejiang Provincial Enterprise Research Institute", "Top 100 Zhejiang Provincial High-tech Enterprise with Innovation Capability", etc. It has won top ten enterprises of Cixi for ten successive years, and particularly in 2018, it won the first place in Cixi's "Hero of Average Returns". In 2021, it won the leader enterprise in Ningbo auto industry and Zhejiang Province in terms of average returns.`,
            },
            card1: {
                title: 'Challenge1',
                content1: {
                    aotecar: 'Offline Excel documents are difficult to establish accurate traceability. It takes a lot of time to work out the traceability and consistency issues.',
                    TongyuAutomobile: 'The wire control braking system is classified as an ASIL D level functional safety component, therefore it needs to be developed following the functional safety process.',
                    Fuerda: 'ASPICE standard requires a plethora of documents and processes. How to meet the requirements of rapid iteration in the automotive field?',
                },
                content2: {
                    aotecar: 'Mind mapping requirements management and V-model views showing traceability can improve management efficiency.',
                    TongyuAutomobile: 'MappingSpace supports HARA, FTA, and DFMEA for functional safety analysis methods.',
                    Fuerda: 'Integrating agile development with ASPICE to improve efficiency while also ensuring quality.',
                }
            },
            card2: {
                title: 'Challenge2',
                content1: {
                    aotecar: 'It is not possible to get a real sense of the historical modification history of the requirements by consulting the Version Upgrade Record form in word.',
                    TongyuAutomobile: 'There is a large amount of research and development documents that need to be quickly migrated to online tools for digital management.',
                    Fuerda: 'The learning bar for IBM Doors is high. Will the introduction of this new tool, MappingSpace, set the bar even higher? How to replace Doors?',
                },
                content2: {
                    aotecar: 'View requirements modification history at any time. Architecture diagram history versioncan be rolled back.',
                    TongyuAutomobile: 'Batch import of Word and Excel documents, building a solid foundation for digital development in research and development.',
                    Fuerda: 'The mastery of global thinking is essential in system engineering based on mind mapping.',
                }
            },
            card3: {
                title: 'Challenge3',
                content1: {
                    aotecar: 'Mind mapping requirements management and V-model views showing traceability can improve management efficiency.',
                    TongyuAutomobile: 'There is a problem of project template reusability when there are too many customer projects.  There is a need to establish traceability again after project reuse.',
                    Fuerda: "Project management is labor-intensive and faces the challenge of multiple projects running concurrently.Can tools be used to replace part of the project manager's work？",
                },
                content2: {
                    aotecar: 'Multiuser real-time evaluation, automatic generation of evaluation records and evaluation data report improves evaluation efficiency.',
                    TongyuAutomobile: 'Project reuse and traceability inheritance, parallel development of platform projects and vehicle projects.',
                    Fuerda: 'Generating real-time timesheet reports from Gantt Chart',
                }
            },
            learn: 'More'
        },
        customerPage3: {
            solve: 'Solution and the Utilisation of MappingSpace Tools:',
            next: 'Next',
            previous: 'Previous',
            card1: {
                title: {
                    aotecar: 'Mind mapping requirements management and V-model views showing traceability can improve management efficiency',
                    TongyuAutomobile: 'Supporting HARA, FTA, and DFMEA for functional safety analysis methods',
                    Fuerda: 'Integrating agile development with ASPICE to improve efficiency while also ensuring quality.',
                },
                content: {
                    aotecar: 'Client maintains a large number of offline process documents (System Requirements Specification, Test Specification, and Traceability Matrix, all in Excel format). In the specifications, dozens of columns of information exist to be filled in for each requirement. System Requirement ID, Stakeholder Requirement ID, Requirement Group ID, etc. need to be maintained for establishing traceability between requirements. In the Traceability Matrix file, all system requirements, system architecture, software requirements, software architecture, software detailing are maintained, and ID numbers need to be maintained and traceability established in each table.',
                    TongyuAutomobile: 'The integrated electronic hydraulic braking system (iEHB) integrates three major braking products: EHB, EPB, and ESC. It can achieve high-quality basic braking, wire control braking, wire control parking, stability control, and other functions, and can serve as a chassis domain controller to fully take over the line control chassis. However, iEHB, as a key component in the autonomous driving scenario, has a very high functional safety level, and therefore requires functional safety product certification.',
                    Fuerda: 'Agile development focuses on rapid iteration, adaptability, and continuous delivery. In contrast, ASPICE sets stringent standards for safety and quality. Without integrating ASPICE, agile teams in the automotive sector could encounter challenges：',
                    li1: '- Compliance Issues: Not meeting industry standards can prevent product certification and market release;',
                    li2: '- Security Risks: Ensuring the safety and reliability of products, and managing the software lifecycle effectively, poses challenges, including quality assurance and lifelong management;',
                    li3: '- Customer Trust Deficit: Clients may lack confidence in software systems developed using agile methodologies, especially those affecting life safety. Building trust requires clear communication and reassurance about the reliability of agile-developed products.',
                },
                li1: {
                    aotecar: 'Based on the field requirements of the Client, it is easy to configure each type of field in MappingSpace;',
                    TongyuAutomobile: 'The HARA, FTA, and DFMEA analysis modules are part of the MappingSpace tool, so the functional safety analysis process is closely integrated with the design process;',
                    Fuerda: 'Import offline Word and Excel documents into MappingSpace with a single click, achieving atomic and itemized management, and utilizing Agile development methods like Scrum and Kanban;',
                },
                li2: {
                    aotecar: 'MappingSpace provides two import methods, Excel and Word, to quickly online offline documents, and establish a globally unique ID for each requirement;',
                    TongyuAutomobile: 'The optimization measures of DFMEA are directly linked to design tasks without the need to consider integrating different tools. This saves a significant amount of manpower and tool procurement costs.',
                    Fuerda: 'Establish high-granularity traceability among requirements, architecture, detailed design, and test cases;',
                },
                li3: {
                    aotecar: 'A mind map view to show the hierarchical relationship of requirements and establish traceability between requirements;',
                    Fuerda: 'Leveraging the native baseline management, change management, and online review features in MappingSpace to meet the process management requirements for the entire ASPICE lifecycle.',
                },
                li4: {
                    aotecar: 'A V-model view to show global traceability and automatically generate a V-model traceability matrix.',
                }
            },
            card2: {
                title: {
                    aotecar: 'View requirements modification history at any time. Architecture diagram history version can be rolled back',
                    TongyuAutomobile: 'Batch import of Word and Excel, build a solid foundation for digital development in research and development',
                    Fuerda: 'The mastery of global thinking is essential in system engineering based on mind mapping.',
                },
                content: {
                    aotecar: 'In the era of using Word documents, the common practice for tracking revision history involves adding a "History of Revision Records". However, this method often lacks in providing detailed insights into specific modifications or the reasons behind them. Another approach is to save multiple versions of Word or Excel files, but manually comparing the differences proves to be inefficient. This ultimately results in a lack of accessibility to detailed information about requirement changes.',
                    TongyuAutomobile: 'Before using the tool, the development team stored a large amount of process documentation offline. This practice has resulted in some management deficiencies. Firstly, offline storage of process documents makes it difficult to access and share information. Team members need to spend a lot of time searching for and obtaining the required documents, which delays project progress. Secondly, because offline documents cannot be updated and edited in real-time, team members may miss the latest changes and updates, thus affecting the accuracy and consistency of the project.',
                    Fuerda: "We've been using IBM Doors for years but are noticing its shortcomings: it's costly and not very user-friendly, with an outdated as well as unintuitive interface, making it hard for new users to learn. Integration with other development tools, especially non-IBM ones, can be complex. There is a consideration of new tools to replace Doors but there are concerns about adding complexity to toolchain, making deployment harder. ",
                },
                li1: {
                    aotecar: "The modification of each field in the requirement has a detailed history, including the modifier, modification time, and modification content;",
                    TongyuAutomobile: 'Offline Word and Excel documents can be imported in bulk into MappingSpace, enabling quick itemization of requirements;',
                    Fuerda: 'MappingSpace adopts the Mind Map Based Systems Engineering (MMBSE) concept, which is consistent with MBSE. Its greatest advantage is that it is intuitive and easy to learn. Once the basics of mind mapping are mastered, one can fully leverage their strengths on MappingSpace;',
                },
                li2: {
                    aotecar: "MappingSpace has a built-in architecture drawing tool, drawio. Drawio is the world's most popular open-source UML drawing tool with features far beyond visio and supports architecture history rollback.",
                    TongyuAutomobile: 'The itemized requirements from online sources can also be exported to Word or Excel for easier information sharing with third-party clients.',
                    Fuerda: 'During the tool import phase, Yunti Technology explores with the customer together using training and Q&A sessions. Yunti Technology has a team of highly experienced experts in the automotive toolchain. In addition, numerous instructional videos made by Yunti Technology experts, as well as professional technical articles they have written, are available online to help customers implement the solutions.',
                },
            },
            card3: {
                title: {
                    aotecar: 'Multiuser real-time evaluation, automatic generation of records and data report improves evaluation efficiency',
                    TongyuAutomobile: 'Project reuse and traceability inheritance, parallel development of platform projects and vehicle projects.',
                    Fuerda: 'Generating real-time timesheet reports with Gantt Charts',
                },
                content: {
                    aotecar: "Requirements need to be reviewed after they have been written. The review of requirements is very rigorous within the Client's company. They need to include the review process, checklist, and the modification process after the review, which needs to be documented. With offline tools, the review records and revision records are all in emails. This makes it difficult to establish effective traceability.",
                    TongyuAutomobile: 'The project manager manages the development of multiple vehicle projects at the same time and there is a high level of duplication of process documentation. The process documentation had previously been managed offline, which did not allow for complete traceability. At the beginning of a new model project, the amount of changes required to directly copy all the process documentation from the original project is high, and it is impossible to know whether the changed design will be applied to other model projects. The project manager needs to deal with the needs of many customers at the same time, and adopts email communication, excel table and other major ways to communicate with customers, which is less efficient.',
                    Fuerda: "Gantt chart is essential, as one of the most important tools in project management. However, there was no available appropriate Gantt chart tool that can integrate with our R&D management system. The intention is that the R&D design tasks only need to be created once and can be added to the Gantt chart simultaneously, instead of being created repeatedly. At the same time, we would be able to track the difference between each person's actual time spent and the theoretical estimate in the Gantt chart, and be able to reflect this gap in real-time through data reports.",
                },
                li1: {
                    aotecar: 'Easily configure the checklist directly in the "Review Configuration" of MappingSpace;',
                    TongyuAutomobile: "We suggested the client to change the original independent project development mode, and switch to maintaining both the platform project and the model project at the same time. Therefore, we gave the client two options to choose from. By maximizing the reuse of process documentation from the platform project, the workload of the project management process could be reduced. The client finally chose the second solution and implemented it, increasing the work efficiency by 50%;",
                    Fuerda: "MappingSpace's Gantt chart perfectly fits customer needs, allowing the seamless addition of various design tasks, project plans, and bugs into the Gantt chart;",
                },
                li2: {
                    aotecar: 'User can easily define the review rules for each file type;',
                    TongyuAutomobile: "By establishing customer accounts and restricting customers' rights in the MappingSpace system, information transfer with customers can be realized. This ensures consistency and timeliness in the format of information and enables issue management for all clients in one system.",
                    Fuerda: "A Gantt chart can include both the project manager's estimated completion time and the engineer's actual hours worked;",
                },
                li3: {
                    aotecar: 'Automatic statistics of evaluation results, and automatic issuance of evaluation data reports.',
                    Fuerda: 'Generate Gantt charts in real-time and automatically, offering data reports that support statistical analysis at any given time.',
                },
            }
        },
        caseCard: {
            title1: 'Aotecar：',
            title2: 'Tongyu：',
            title3: 'Fuerda：',
            title1Text:'Comprehensive Automotive Thermal Management Solution',
            title2Text:'Leading Technology Innovator in Chassis Control',
            title3Text:'High-End Automotive Intelligent Optoelectronic System',
            more:'more'
        }
    },
    toolchain: {  // 汽车工具链
        toolchainPage1: {
            name: 'Automotive Toolchain',
            content: 'MappingSpace brings you a more comprehensive and knowledgeable car R&D management toolchain.',
        },
        toolchainPage2: {
            title: 'MappingSpace: User-Friendly Integration',
            content: 'MappingSpace is a research and development management software specifically designed for the automotive industry. The automotive industry involves a multitude of tools, so we are proactively integrating with various efficient tools to provide you with a more comprehensive and automotive-savvy R&D management toolchain.',
            systemRequirement: 'System requirement',
            systemArchitecture: 'System architecture',
            SWRequirement: 'SW requirement',
            SWArchitecture: 'SW architecture',
            SWDetailedDesign: 'SW detailed design',
            coding: 'Coding',
            SWUnitVerification: 'SW unit verification',
            SWIntegrationTest: 'SW integration test',
            SWQualificationTest: 'SW qualification test',
            systemIntegrationTest: 'System integration test',
            systemQualificationTest: 'System qualification test',
        },
        partner: {
            eolink: {
                content: "Assist the manufacturer's R&D team in efficiently managing API documentation. Based on the API documentation, help the front-end, back-end, and testing teams collaborate to quickly initiate API tests, facilitate front-end and back-end integration with MockAPI, and provide capability for API test report generation.",
                content1: 'By integrating with MappingSpace, interface design documents and API interfaces are directly connected. Additionally, API test reports can be directly viewed within MappingSpace.',
                li1: 'Version control, differential comparison, and change notification make API documentation truly usable!',
                li2: 'Powerful custom request validation and response facilitate scenarios such as frontend-backend integration and integration testing!',
                li3: 'Provide a detailed test report, including return headers, return results, performance time analysis, etc！',
                li4: 'Comprehensive coverage of API development and management tools like Swagger and Postman, integrating with Mappingspace for tool unification!',
            },
            feyshTechnology: {
                content1: {
                    title: 'Corax is used to enhance software quality and security, and integrating with MappingSpace will meet automotive industry compliance requirements',
                    content: "Our software development team can integrate Corax's automated code scanning capabilities into their pipeline. This enables them to identify potential bugs and security vulnerabilities in the code at an early stage, allowing them to be fixed with minimal expense before the software is released. By integrating with MappingSpace, users can view vulnerability scan reports within MappingSpace.",
                },
                content2: {
                    title: 'Embed industry standards to meet compliance requirements',
                    content: 'Corax includes built-in quality and safety standards common in the automotive industry, such as MISRA C/C++, AUTOSAR C++, CERT C/C++, CWE, and OWASP. This can assist R&D teams in achieving compliance with ISO 26262 and ISO 21434.',
                },
                content3: {
                    title: 'Compliance with GPU programming standards to enhance the stability of autonomous driving software',
                    content: 'Corax also supports analysis of heterogeneous programming models such as CUDA, in addition to traditional embedded development environments and code. This can help development teams effectively improve the quality and safety of autonomous driving software.',
                },
                content4: {
                    title: 'Open interfaces for rapid integration of R&D pipelines',
                    content: 'Corax adopts a modular design, decoupling code analysis from data analysis capabilities. The development team can utilize a variety of API interfaces provided by Corax to achieve seamless pipeline integration, allowing for monitoring and management of code quality and security across different research and development workflows.',
                }
            },
            gitlab: {
                content1: {
                    title: 'Top Open Source Projects',
                    li1: "Open and transparent source code improves code quality and security.",
                    li2: 'Over 3,300 code contributors and over 10,000 community contributors, a thriving developer ecosystem.',
                    li3: 'In 12 years, continue to innovate by choosing a 139+ version release.',
                },
                content2: {
                    title: 'Widespread Application',
                    li1: "Over 100,000 enterprise users with private deployment",
                    li2: 'Over 30 million registered users',
                    li3: 'SaaS supports hundreds of thousands of users and millions of projects every month.',
                    li4: '67% Private Deployment DevOps Global Market Share',
                },
                content3: {
                    title: 'Integrated efficiently with MappingSpace',
                    li1: "By integrating with MappingSpace, users can link code submissions and CI/CD results directly to research and development process documents.",
                    li2: 'Enhance project management efficiency and processes, achieving a closed loop between development and codes.',
                },
                content4: {
                    title: 'AI Empowerment',
                    li1: "AI capabilities throughout the entire software development lifecycle",
                    li2: 'Enterprise-level AI services with privacy-first',
                    li3: 'Transparent AI feature evolution roadmap',
                },
            }
        }
    },
    informationSecurity: {   // 信息安全
        title: 'Threat Analysis and Risk Assessment (TARA)',
        title1: 'Compliance with WP.29-R155 Cyber Security Regulations',
        li1: 'Built-in WP.29-R155 threat list and mitigation measures database, your privacy Cyber security identification expert.',
        li2: 'Rapid identification of Cyber security assets based on mind mapping.',
        li3: 'Automatically calculate damage impacts based on SFOP.',
        li4: 'Export TARA analytics table quickly.'
    },
    industrialIntelligentManufacturing: {   // 工业智造
        industrialPage1: {
            name: 'R&D management in industrial intelligent manufacturing field',
            content: 'MappingSpace meets the standards of high-precision industrial fields,one-stop R&D digital management platform',
        },
        industrialPage2: {
            title: 'MappingSpace with industrial intelligent manufacturing field',
            li1: "MappingSpace's R&D and design concepts originated from ISO 26262, the functional safety standard for the automotive industry, and have been expanded to cover a wide range of industrialized fields, such as aerospace (DO-178C, DO-254), medical devices (IEC-60601), rail transportation (EN-5012X), and electrical equipment (IEC-61800), etc.",
            li2: 'MappingSpace incorporates the latest advances in the AIGC field and uses AI large-language model to generate R&D process documentation, help engineers write test cases, generate code, and provide the latest technology query capabilities to greatly enhance R&D progress.',
            li3: 'MappingSpace can help customers pass industry standard certifications faster, establish efficient R&D process management, and realize enterprise costs savings and improved efficiency.'
        }
    },
    funcPage: {    //功能介绍
        barText1:'Overview',
        barText2:'Best practices',
        barText3:'Tool support',
        barText4:'Client cases',
        barText5: 'More',
        trial:'Free trial',
        minjie: {
            title0: 'Agile software development',
            word0: 'Implementing Agile Development based on the ASPICE standard brings \na rapid, iterative development pace to the automotive industry.',
            title1: 'About agile development ',
            word1: `Agile development is not a standard but a combination of ideas, tools, and practices.
                    For example, for a product with unclear requirements, don't expect to get everything right and complete in one go, as the requirements are constantly changing. Start quickly, build a prototype, and through continuous user feedback and interaction, rapidly iterate to the next version, allowing the product to evolve constantly. During this process, user satisfaction continuously increases, and the product requirements become clearer.
                    Common agile development management tools include Scrum, Kanban, prioritization of requirements, agile reports, feature retrospectives, daily stand-up meetings, and more.`,
            title2: 'Practices for integrating development with ASPICE',
            word2:{
                w1_0: 'Translate requirements \ndocuments into itemized lists',
                w2_0: 'Documentation is a crucial foundation for ASPICE compliance. By breaking the documentation into individual requirements, \nprioritizing these requirements, assigning them to individuals, and linking them to sprints, Agile development can be effectively executed within the team.',
                w1_1: 'V-model traceability',
                w2_1: 'Achieve traceability between requirements, architecture, detailed design, test cases, and bugs, while being able to display coverage data in real time, thus meeting the high traceability standards of ASPICE.',
                w1_2: 'Integrate with CICD tools',
                w2_2: 'Code submission and merging are among the most important activities in Agile development. By linking code to detailed design or bugs and automatically generating release notes, a more efficient product release can be achieved.'
                },
            title3: 'Tool support method',
            word3: `In MappingSpace, development process documents are created using mind maps, achieving dual objectives of documenting in Word format and itemizing requirements. We refer to this significant innovation as Mind Mapping Based Systems Engineering.
                    In MappingSpace, engineers can easily create traceability in bulk, while both engineers and managers can view various types of traceability and coverage from perspectives such as V-model or W-model, simplifying the ASPICE review process significantly.`
        },
        vModel: {
            title0: 'V-model development process',
            word0: "Whether it's ASPICE 3.1, the upgraded 4.0 version, or supporting functional safety and information \nsecurity processes, MappingSpace always delivers the best solutions with its flexible configuration pages.",
            title1: 'About V-model development process',
            word1: `The core development frameworks in the automotive industry, such as ASPICE, ISO 26262, and ISO 21434, all adhere to the V-model development process.
                    In the avionics field, DO-178C and DO-278A also follow the V-model development process for their core frameworks.
                    The V-model development process generally includes: system requirements analysis and architecture design, software requirements analysis and architecture design, detailed software design and coding, and various types of test cases.
                    The V-model development process places great emphasis on the retention of docs to demonstrate compliance with specific industry standards. The traceability requirements further reinforce regulatory compliance and enhance product safety and quality standards.`,
            title2: 'Practices of V-model development process',
            word2:{
                w1_0: 'Project document templates',
                w2_0: "The V-model development process has stringent documentation requirements. Providing comprehensive document templates and project configuration templates at the outset can greatly simplify the team's work.",
                w1_1: 'Visualization of the V-model',
                w2_1: 'Traceability between requirements, architecture, detailed design, test cases, and bugs is achieved, and the relationships between documents are visualized in real-time through interactive pages.',
                w1_2: 'Automated generation of \nreview records',
                w2_2: 'The review process involves numerous personnel and requires maintaining review records. If review records could be generated automatically and review issues recorded automatically, it would save the team a significant amount of time.'
                },
            title3: 'Tool support method',
            word3: `MappingSpace provides a rich set of templates for ASPICE, ISO 26262, ISO 21434 projects, and DO-178C/DO-278A avionics projects, significantly simplifying the team's adherence to the V-model development process and greatly enhancing the efficiency of team certification through reviews.
                    With its unique V-model and W-model views, MappingSpace enables team members to have a clearer view of traceability between tasks.
                    MappingSpace's unique and concise review interface offers users the most intuitive and straightforward review experience. It automatically collects review comments and presents detailed review results to you.`
        },
        demand: {
            title0: 'Requirement management',
            word0: 'A well-structured requirements document lays the strongest foundation for a successful project.',
            title1: 'About requirement management',
            word1: `In industry, requirements are traditionally managed by type. For example, in the automotive sector, requirements are categorized into: system requirements, 
                    software requirements, hardware requirements. In the avionics sector, requirements are categorized into: high-level requirements, low-level requirements, hardware requirements.`,
            title2: 'Practices of requirement management',
            word2:{
                w1_0: 'Traceability of requirements',
                w2_0: 'By establishing traceability between requirements and architecture, detailed design, and test cases, complete coverage of requirements is achieved. When there are changes to the requirements, relevant personnel can be promptly notified.',
                w1_1: 'Structured requirements',
                w2_1: 'Structured requirements mean that the writing and storage of requirements are organized in a structured manner, allowing for clearer management of various attributes of the requirements and enabling efficient addition, deletion, modification, and querying.',
                w1_2: 'Requirements reuse',
                w2_2: "Requirements in one project can be easily reused in other projects while maintaining their traceability. This reuse of requirements can significantly enhance the team's development efficiency."
                },
            title3: 'Tool support method',
            word3: `Using mind map and Word modes, MappingSpace helps users quickly document and structure requirements. In MappingSpace, the granularity of requirements is not limited by Epic, Feature, Story, etc. Users can decompose requirements infinitely based on their needs, achieving refined requirements management.
                    Once the requirements in a project are completed, they can be easily copied to a new project along with their traceability. In the new project, the requirements can be developed independently.`
        },
        ai: {
            title0: 'AI content generation technology',
            word0: 'AI can assist engineers in quickly writing requirement documents, test cases, and drawing architecture diagrams, greatly accelerating project progress.',
            title1: 'About AI content generation technology',
            word1: `Using artificial intelligence technology to generate content.
                    For example, having a direct conversation with the ChatGPT large model and using AI technology to generate text, images, videos, etc.
                    Applying AI technology in the field of engineering and research development can assist engineers in quickly generating research and development process documents, drawing architecture diagrams, and creating code frameworks.`,
            title2: 'Practices of AI content generation technology',
            word2:{
                w1_0: 'AI-generated product',
                w2_0: 'Users describe requirements directly to AI, which quickly generates product prototypes and source code. Users validate them rapidly and provide feedback for modifications. AI then iterates quickly based on this feedback.',
                w1_1: 'AI-generated documents',
                w2_1: 'Using AI to generate most of the document content, such as one-click generation of project management plans, test management plans, electric motor requirement designs, complex structural designs, etc.',
                w1_2: 'AI-generated test case code',
                w2_2: 'AI can understand complex product requirements or development code, and based on this, generate test case code to enhance the efficiency of unit testing.'
                },
            title3: 'Tool support method',
            word3: `In MappingSpace, users can converse anytime with the built-in AI robot. Whether it's helping you write documents, create architecture diagrams, or generate code, the AI robot is always ready to assist.
                    With the all-new MappingGPT, you simply input a basic requirement description, and the AI robot immediately generates detailed requirement breakdown documents, project plans, development tasks, implementation code, and even handles automated compilation and deployment. You only need to proceed with further testing, validation, and modifications based on this foundation.`
        },
        test: {
            title0: 'Test management',
            word0: "Whether it's manual test cases or automated test scripts, MappingSpace always provides the best traceability solutions.",
            title1: 'About test management',
            word1: `Test management includes at least: test case management, test case review, test plan management, test report management, bug creation and tracking, and linking test cases with requirements. 
                    In addition, test management may also involve how to associate requirements with automated test scripts, how to manage test benches, and how to centrally manage test reports.`,
            title2:'Practices of test management',
            word2:{
                w1_0: 'automatic test case selection',
                w2_0: 'Once the software under test package is identified, it automatically filters out the test cases that need to be executed from the test case repository. After execution is completed, it automatically generates the test report.',
                w1_1: 'traceability of test cases to \nrequirements',
                w2_1: 'Batch creation of test cases with traceability to requirements, and the ability to view real-time coverage percentages for various types of requirements.',
                w1_2: 'automated test management',
                w2_2: 'Integrate with various automation testing tools to establish traceability between automated test cases and requirements, while also consolidating test reports onto a unified page to display more comprehensive test results.'
                },
            title3: 'Tool support method',
            word3: `MappingSpace can simultaneously support manual test case and automated test case management, and can integrate with multiple automated test management tools.
                    After creating a test plan, test cases corresponding to the development scope of the current test plan are automatically added. Additionally, test cases can be manually added, facilitating the precise creation of test plans and regression test plans, thereby reducing the workload of adding test cases each time.
                    Test cases can be traced bi-directionally with requirements, and maintain traceability relationships unchanged during subsequent project reuse, significantly enhancing project reuse efficiency.`
        },
        baseLine: {
            title0: 'Baseline and change management',
            word0: 'The essence of baseline management is to record the starting point of the project as well as the change history based on that starting point.',
            title1: 'About baseline and change management',
            word1: `In the latest ASPICE standard, the term "Baseline" appears 32 times, highlighting the importance of baseline management.P
                    roject management typically involves multiple stages such as market research, concept design, prototype design, product definition, functional design, and architecture design. A baseline marks the starting point of a project during a specific period. At this starting point, it includes all inputs such as requirements, settings, specifications, and others for the entire project within that timeframe.
                    Changes usually refer to modifications made against a specific baseline. Since the baseline defines the starting point and any changes to it typically involve various team members, a change review process is necessary.`,
            title2:'Practices of baseline and change',
            word2:{
                w1_0: 'Create baselines at any time',
                w2_0: 'Create a baseline and add process documents to it at any time. Baseline permissions can be restricted to individuals, and the baseline content can be displayed to the entire team at any time, ensuring the entire team develops based on the same baseline.',
                w1_1: 'Submit change requests at \nany time',
                w2_1: 'The process for submitting changes should be convenient and quick, and it should help identify the impact scope of the changes and the change review committee.',
                w1_2: 'Record baseline changes at \nany time',
                w2_2: 'When changes occur, they will inevitably affect the baseline content. The ability to display the baseline change history in real-time ensures that all relevant personnel are notified.'
                },
            title3: 'Tool support method',
            word3: `Baselines and changes should be managed as a unified entity, precisely because changes are frequent, and every state in the process needs to be documented.
                    MappingSpace draws inspiration from the concepts of mainline and branches in Git, allowing everyone to submit change requests as needed. The final approval of change requests always requires approval from the Change Control Board to ensure the validity of the change request.`
        }
    }
}
